import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <div>Cryptocurrency prices are from <a href="https://www.coingecko.com/en/api" target="_blank">CoinGecko API</a> and supply data is from crypto project's service. Everything else is calculated.</div>
            <br />
            <div>
                DISCLAIMER: All content provided on our website is for general information only.
                No part of the content that we provide constitutes financial advice, legal advice or any other form of advice meant for your specific reliance for any purpose.
                Any use or reliance on the crypto prices, projects circulating supply, inflation data or any other content is solely at your own risk and discretion.
                You should conduct your own research, review and analysis. Do not trust, verify.
            </div>
            <br />            
            <div>
                <a className="linkPadding" href="https://twitter.com/MarketCapTrakr" target="_blank">X (Twitter)</a>
                <a className="linkPadding" href="https://discord.gg/6PSS69WSTW" target="_blank">Discord</a>
                <a className="linkPadding" href="https://t.me/NervaCrypto" target="_blank">Telegram</a>                  
            </div>
            <div>© 2024 MarketCapTracker. All rights reserved.</div>
        </div>
    )
}

export default Footer