import './components/styles/app.css';
import React, { useRef, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './pages/Footer';
import Top10Hourly from './pages/Top10Hourly';
import Top10Daily from './pages/Top10Daily';
import Top10Weekly from './pages/Top10Weekly';
import About from './pages/About';
import BlogIndex from './blog/pages/BlogIndex';
import BlogPost from './blog/pages/BlogPost';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const darkModeFromStorage = localStorage.getItem('dark_mode')
  const[darkMode, setDarkMode]=useState();

  // For PROD, first line needs to be uncommented. For DEV, 2nd one!
  window.$baseUrl = "/api";
  //window.$baseUrl = "http://localhost:3001";

  // Load state from local storage
  useEffect(() => {
      const darkModeFromStorage = localStorage.getItem('dark_mode');

      if(darkModeFromStorage === "true") {
        setDarkMode(true); 
      }
      else {
        if(darkModeFromStorage != "false") {
          if(prefersDarkMode === true) {
            setDarkMode(true);
          }
          else {
            setDarkMode(false);
          }
        }
      }         
    }, []);

  // Save states to local storage
  useEffect(() => {
    if(darkMode === undefined) return;
      localStorage.setItem('dark_mode', darkMode); 
  }, [darkMode]);

  // Main theme colors
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          background: {
            default: darkMode ? '#191919' : '#E9E9E9',
          },
          text: {
            primary: darkMode ? '#AAA6A6' : '#000000',
          },
        },

      }),
    [darkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <main className="main-content">
          <NavBar handleThemeChange={()=>setDarkMode(!darkMode)} />
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/top-10-hourly" element={<Top10Hourly/>}/>
            <Route path="/top-10-daily" element={<Top10Daily/>}/>
            <Route path="/top-10-weekly" element={<Top10Weekly/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/blog" element={<BlogIndex/>}/>
            <Route path="/blog/:url_path" element={<BlogPost/>}/>
          </Routes>
          <Footer />
        </main>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;