import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from 'axios'

export default function BlogPost() {
  const [postInfo,setPostInfo] = useState(null);
  const {url_path} = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get(window.$baseUrl + `/post/${url_path}`, { 
              params: { 
                  referrer: document.referrer, 
                  language: navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language,
                  resolution: window.screen.width + "x" + window.screen.height
              }
          });

          setPostInfo(response.data);
      } catch (error) {
          console.error(error);
          return;
      }
    };

    fetchData();
  }, []);


  if (!postInfo) return '';

  return (
    <center>
        <div className="blogPostPage">
            <div className="blogPostImage">
                <img src={postInfo[0].image_url} alt=""/>
            </div>
            <h1 className="blogPostTitle">{postInfo[0].title}</h1>
            <div className="blogPostTime">Posted on {postInfo[0].display_date} by anon</div>
            <div className="blogPostContent" dangerouslySetInnerHTML={{__html:postInfo[0].content}} />
        </div>
    </center>
  );
}