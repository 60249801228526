import React from 'react';
import { Link } from "react-router-dom";
import './styles/app.css';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function NavBar({handleThemeChange}) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
    return (
      <nav>
        <Link to="/" className="nav_link"><div className="homeLinkAligh"><img src="https://marketcaptracker.com/static/images/mct_logo.png" height="30" />Home</div></Link>
        <Link to="/top-10-hourly" className="nav_link" >Top 10 Hourly</Link>
        <Link to="/top-10-daily" className="nav_link" >Top 10 Daily</Link>
        <Link to="/top-10-weekly" className="nav_link" >Top 10 Weekly</Link>
        <Link to="/about" className="nav_link">About</Link>
        <Link to="/blog" className="nav_link">Blog</Link>
        <IconButton sx={{ ml: 1 }} onClick={handleThemeChange} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
      </nav>
    );
}

    export default NavBar;