import {useEffect, useState} from "react";
import axios from 'axios'
import Post from "../Post";

export default function BlogIndex() {
    const [posts,setPosts] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await axios.get(window.$baseUrl + "/blog-posts/", { 
                params: { 
                    referrer: document.referrer, 
                    language: navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language,
                    resolution: window.screen.width + "x" + window.screen.height
                }
            });

            setPosts(response.data);
        } catch (error) {
            console.error(error);
            return;
        }
      };

      fetchData();
    }, []);

    return (
      <center>
          <div className="blogIndex">
          {posts.length > 0 && posts.map(post => (
              <Post {...post} />
          ))}
          </div>
      </center>
    );
}