import { useEffect, useMemo, useState } from 'react';
import axios from 'axios'
import { useTheme } from '@mui/material/styles';
import XIcon from '@mui/icons-material/X';
import { 
    MaterialReactTable, 
    MRT_ToggleFullScreenButton, 
    MRT_ToggleDensePaddingButton,
    useMaterialReactTable } from 'material-react-table';
import { Box, Typography, Tooltip, Button } from '@mui/material';

const Top10Daily = () => {
    const theme = useTheme();

    const [density, setDensity] = useState('compact');

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    // %40 = @
    // %3A = :
    // %2C = ,
    // %20 = space
    // %0A = Enter

    useEffect(() => {
        const fetchData = async () => {
        if (!data.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const response = await axios.get(window.$baseUrl + "/top-10-daily/", { 
                params: { 
                    referrer: document.referrer, 
                    language: navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language,
                    resolution: window.screen.width + "x" + window.screen.height
                }
            });
            setData(response.data);
            setRowCount(response.data.length);
            window.snapshotDate = response.data[0].date;
            window.winnersDaily = "Top%2010%20Daily%20Market%20Cap%20Gainers%20-%20" + response.data[0].date
                + "%0A🥇%20$" + response.data[0].ticker + "%20@" + response.data[0].twitter_handle 
                + "%0A🥈%20$" + response.data[1].ticker + "%20@" + response.data[1].twitter_handle 
                + "%0A🥉%20$" + response.data[2].ticker + "%20@" + response.data[2].twitter_handle 
                + "%0A4⃣%20$" + response.data[3].ticker + "%20@" + response.data[3].twitter_handle 
                + "%0A5⃣%20$" + response.data[4].ticker + "%20@" + response.data[4].twitter_handle 
                + "%0A6⃣%20$" + response.data[5].ticker + "%20@" + response.data[5].twitter_handle
                + "%0A7⃣%20$"+ response.data[6].ticker + "%20@" + response.data[6].twitter_handle 
                + "%0A8⃣%20$" + response.data[7].ticker + "%20@" + response.data[7].twitter_handle 
                + "%0A9⃣%20$"+ response.data[8].ticker + "%20@" + response.data[8].twitter_handle
                + "%0A🔟%20$" + response.data[9].ticker + "%20@" + response.data[9].twitter_handle
                + "%0ASource: ";
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        };

        fetchData();
    }, []);
  
    const imageUrl = "https://marketcaptracker.com/static/images/coins/";
    const rankUrl = "https://marketcaptracker.com/static/images/top10/";
    const xShareUrl = "https://twitter.com/intent/tweet?url=https://marketcaptracker.com&text=" + window.winnersDaily;

    const columns = useMemo(
        () => [
        {
            accessorKey: 'place',
            header: 'Rank',
            maxSize: 80,
            Cell: ({ row }) => {
                return (
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                    >
                        <img
                            alt="icon"
                            height={40}
                            src={rankUrl + row.original.place + ".png"}
                            loading="lazy"
                            style={{ borderRadius: '50%' }}
                        />
                    </Box>
                );
            },
        },
        {
            accessorFn: (row) => `${row.name} ${row.ticker}`, //accessorFn used to join multiple data into a single cell
            id: 'name',
            header: 'Coin',
            enableResizing: true,
            enableHiding: false,
            grow: true,
            Cell: ({ row }) => (
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                }}
                >
                    <img
                        alt="icon"
                        height={40}
                        src={imageUrl + row.original.ticker.toLowerCase() + "-" + row.original.coingecko_api_id.toLowerCase() + ".png"}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                    />
                    <div className="table-text">{row.original.name}<br />{row.original.ticker}</div>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.current_mc} ${row.ath_mc}`, //accessorFn used to join multiple data into a single cell
            id: 'market_cap',
            header: 'MC Current/ATH',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        ${row.original.current_mc.toLocaleString()}<br />
                        ${row.original.ath_mc.toLocaleString()}
                    </div>
                );
            },
        },
        {
            accessorFn: (row) => `${row.ath_progress} ${row.ath_x}`, //accessorFn used to join multiple data into a single cell
            id: 'progress_to_ath',
            header: 'Progress to ATH',

            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 160,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        {row.original.ath_progress.toFixed(2)}%<br />
                        {row.original.ath_x.toFixed(2)}x
                    </div>
                );
            },
        },
        {
            accessorKey: 'change_1day',
            header: '1D Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1day === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1day > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1day.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1day.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1week',
            header: '1W Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1week === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1week > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1week.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1week.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1month',
            header: '1M Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1month === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1month > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1month.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1month.toFixed(2)}%
                        </div>
                    );
                }
            },
        },        
        {
            accessorKey: 'current_price',
            header: 'Price',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 130,
            minSize: 80,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        ${row.original.current_price}
                    </div>
                );
            },
        },
        {
            accessorKey: 'current_supply',
            header: 'Current Supply',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 110,
            minSize: 80,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        {row.original.current_supply.toLocaleString()}
                    </div>
                );
            },
        },        
        //column definitions...
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: false,
        initialState: { showColumnFilters: false },
        manualFiltering: false,
        enablePagination: false,
        manualPagination: false,
        enableSorting: false,
        enableColumnActions: false,
        muiToolbarAlertBannerProps: isError? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#242424' : '#F4F4F4',            
                },
                '& tr:nth-of-type(even) > td': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#191919' : '#E9E9E9',            
                },
            },
        },
        renderTopToolbarCustomActions: () => 
            <Typography align="left">
                Top 10 Daily for {window.snapshotDate}            
            </Typography>,
        renderToolbarInternalActions: ({ table }) => (
            <>
                <Tooltip title="Share on X (Twitter)">
                    <a href={xShareUrl} target="_blank" rel="noreferrer">
                        <Button
                            size="sm"
                            variant="plain"
                            color="primary"
                            >
                            <XIcon sx={{ mr: 0.5 }} color="inherit" /> 
                        </Button>
                    </a>
                </Tooltip>
            
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />            
            </>        
        ),
        onDensityChange: setDensity,
        rowCount,
        state: {
            density,
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
        },
    });

    return( <MaterialReactTable table={table} /> );
};

export default Top10Daily;