import React from 'react'

const About = () => {
    return (
        <div className="about">
            <br />
            <h2>About Market Cap Tracker</h2>
            <p>
                Wouldn't it be nice if you could look at crypto projects from market cap perspective instead of just price perspective?
            </p>
            <p>
                What progress has given coin made in relation to its all time high market cap? How far away from that high is it?                
            </p>
            <p>
                Projects that distribute their supply through mining can have the same market cap even though the price might be lower.                
            </p>
            <p>
                This is because there might have been a lot less supply a year ago so even though the price might now be lower, market cap might be higher!
            </p>
            <p>
                That's what we attempt to look at. Get accurate supply, keep track of that data and calculate gaines based on past market cap!
            </p>
            <br />            
            <h2>Listing requirements</h2>
            <p>
                To be listed on Market Cap Tracker website, crypto project neeeds to:
            </p>            
            <ul>
                <li>- Be listed on CoinGecko</li>
                <li>- Have its own blockchain</li>
                <li>- Have its own API that returns current supply</li>
                <li>- Produce new coins through minig, staking or some other means</li>
            </ul>
            <br />
            <h2>How to get listed (contact us)</h2>
            <p>
                Best way to reach us is through <a href="https://discord.gg/6PSS69WSTW" target="_blank">Nerva's Discord Server</a>
                or <a href="https://t.me/NervaCrypto" target="_blank">Nerva's Telegram Group</a>
            </p>
            <p>
                If your favorite cryptocurrency is not listed but meets above listing requirements get in touch with us!
            </p>
            <p>
                If you see issues, inconsistencies or have suggestions on how to make this website better, let us know!
            </p>
        </div>
    )
}

export default About