import { useEffect, useMemo, useState } from 'react';
import axios from 'axios'
import { useTheme } from '@mui/material/styles';
import { 
    MaterialReactTable, 
    MRT_ShowHideColumnsButton, 
    MRT_ToggleFullScreenButton, 
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable } from 'material-react-table';
import { Box, Typography, Tooltip, Switch } from '@mui/material';

const Home = () => {
    const [stickyHeaderChecked, setStickyHeaderChecked] = useState(false);
    const handleStickyHeaderChange = (event) => {
        setStickyHeaderChecked(event.target.checked);
      };

    const theme = useTheme();

    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [density, setDensity] = useState('compact');
    const [globalFilter, setGlobalFilter] = useState(undefined);
    const [showGlobalFilter, setShowGlobalFilter] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
      });

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
        if (!data.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const response = await axios.get(window.$baseUrl + "/", { 
                params: { 
                    referrer: document.referrer, 
                    language: navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language,
                    resolution: window.screen.width + "x" + window.screen.height
                }
            });
            setData(response.data);
            setRowCount(response.data.length);
            window.snapshotDate = response.data[0].date;
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        }
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        };

        fetchData();
    }, []);

    // Load state from local storage
    useEffect(() => {
        const columnFilters = localStorage.getItem('mrt_columnFilters_table_1');
        const columnVisibility = localStorage.getItem(
            'mrt_columnVisibility_table_1',
        );
        const density = localStorage.getItem('mrt_density_table_1');
        const globalFilter = localStorage.getItem('mrt_globalFilter_table_1');
        const showGlobalFilter = localStorage.getItem(
        'mrt_showGlobalFilter_table_1',
        );
        const showColumnFilters = localStorage.getItem(
        'mrt_showColumnFilters_table_1',
        );
        const sorting = localStorage.getItem('mrt_sorting_table_1');

        if (columnFilters) {
            setColumnFilters(JSON.parse(columnFilters));
        }
        if (columnVisibility) {
            setColumnVisibility(JSON.parse(columnVisibility));
        }
        else {
            setColumnVisibility(JSON.parse("{\"ath_mc_date\":false,\"change_3months\":false,\"change_6months\":false}"));
        }
        if (density) {
            setDensity(JSON.parse(density));
        }
        if (globalFilter) {
            setGlobalFilter(JSON.parse(globalFilter) || undefined);
        }
        if (showGlobalFilter) {
            setShowGlobalFilter(JSON.parse(showGlobalFilter));
        }
        if (showColumnFilters) {
            setShowColumnFilters(JSON.parse(showColumnFilters));
        }
        if (sorting) {
            setSorting(JSON.parse(sorting));
        }
    }, []);

    // Save states to local storage
    useEffect(() => {
        if(JSON.stringify(columnFilters) === '{}') return;
        localStorage.setItem('mrt_columnFilters_table_1', JSON.stringify(columnFilters));
    }, [columnFilters]);

    useEffect(() => {
        if(JSON.stringify(columnVisibility) === '{}') return;    
        localStorage.setItem('mrt_columnVisibility_table_1',JSON.stringify(columnVisibility));
    }, [columnVisibility]);

    useEffect(() => {
        localStorage.setItem('mrt_density_table_1', JSON.stringify(density));
    }, [density]);

    useEffect(() => {
        if(JSON.stringify(globalFilter) === '{}') return;
        localStorage.setItem('mrt_globalFilter_table_1', JSON.stringify(globalFilter ?? ''));
    }, [globalFilter]);

    useEffect(() => {
        if(JSON.stringify(showGlobalFilter) === '{}') return;
        localStorage.setItem('mrt_showGlobalFilter_table_1', JSON.stringify(showGlobalFilter));
    }, [showGlobalFilter]);

    useEffect(() => {
        if(JSON.stringify(showGlobalFilter) === '{}') return;
        localStorage.setItem('mrt_showColumnFilters_table_1', JSON.stringify(showColumnFilters));
    }, [showColumnFilters]);

    useEffect(() => {
        if(JSON.stringify(sorting) === '{}') return;
        localStorage.setItem('mrt_sorting_table_1', JSON.stringify(sorting));
    }, [sorting]);

    // TODO: Not used. Might want to implement reset to default in the future
    const resetState = () => {
        localStorage.removeItem('mrt_columnFilters_table_1');
        localStorage.removeItem('mrt_columnVisibility_table_1');
        localStorage.removeItem('mrt_density_table_1');
        localStorage.removeItem('mrt_globalFilter_table_1');
        localStorage.removeItem('mrt_showGlobalFilter_table_1');
        localStorage.removeItem('mrt_showColumnFilters_table_1');
        localStorage.removeItem('mrt_sorting_table_1');
        window.location.reload();
    };
  
    const imageUrl = "https://marketcaptracker.com/static/images/coins/";
    const columns = useMemo(
        () => [
        {
            accessorFn: (row) => `${row.name} ${row.ticker}`, //accessorFn used to join multiple data into a single cell
            id: 'name',
            header: 'Coin',
            enableResizing: true,
            enableHiding: false,
            grow: true,
            Cell: ({ row }) => (
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                }}
                >
                    <img
                        alt="icon"
                        height={40}
                        src={imageUrl + row.original.ticker.toLowerCase() + "-" + row.original.coingecko_api_id.toLowerCase() + ".png"}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                    />
                    <div className="table-text">{row.original.name}<br />{row.original.ticker}</div>
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row.current_mc} ${row.ath_mc}`, //accessorFn used to join multiple data into a single cell
            id: 'market_cap',
            header: 'Market Cap Current/ATH',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        ${row.original.current_mc.toLocaleString()}<br />
                        ${row.original.ath_mc.toLocaleString()}
                    </div>
                );
            },
        },
        {
            accessorKey: 'ath_mc_date',
            header: 'ATH Date',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        {row.original.ath_mc_date}
                    </div>
                );
            },
        },
        {
            accessorFn: (row) => `${row.ath_progress} ${row.ath_x}`, //accessorFn used to join multiple data into a single cell
            id: 'progress_to_ath',
            header: 'Progress to ATH',

            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 160,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        {row.original.ath_progress.toFixed(2)}%<br />
                        {row.original.ath_x.toFixed(2)}x
                    </div>
                );
            },
        },
        {
            accessorKey: 'change_1hour',
            header: '1H Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1hour === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1hour > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1hour.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1hour.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1day',
            header: '1D Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1day === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1day > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1day.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1day.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1week',
            header: '1W Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1week === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1week > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1week.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1week.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1month',
            header: '1M Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1month === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1month > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1month.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1month.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_3months',
            header: '3M Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_3months === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_3months > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_3months.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_3months.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_6months',
            header: '6M Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_6months === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_6months > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_6months.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_6months.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'change_1year',
            header: '1Y Change',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                if(row.original.change_1year === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else if(row.original.change_1year > 0) {
                    return (
                        <div className="plus">
                            {row.original.change_1year.toFixed(2)}%
                        </div>
                    );
                } else {
                    return (
                        <div className="minus">
                            {row.original.change_1year.toFixed(2)}%
                        </div>
                    );
                }
            },
        },
        {
            accessorKey: 'current_price',
            header: 'Price',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 130,
            minSize: 80,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        ${row.original.current_price}
                    </div>
                );
            },
        },
        {
            accessorKey: 'current_supply',
            header: 'Current Supply',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 110,
            minSize: 80,
            Cell: ({ row }) => {
                return (
                    <div className="table-text align-right">
                        {row.original.current_supply.toLocaleString()}
                    </div>
                );
            },
        },
        {
            accessorKey: 'annual_inflation',
            header: 'Annual Inflation',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 110,
            minSize: 80,
            Cell: ({ row }) => {
                if(row.original.annual_inflation === 0) {
                    return (
                        <div>
                            -
                        </div>
                    );
                }
                else
                {
                    return (
                    <div className="table-text align-right">
                        {row.original.annual_inflation.toFixed(2)}%
                    </div>
                    );
                } 
            },
        },
        {
            accessorKey: 'links',
            header: 'Links',
            muiTableHeadCellProps: {
                align: 'right',
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
            maxSize: 100,
            Cell: ({ row }) => {
                return (
                    <div>                    
                        <a className="linkPadding" href={row.original.website_url} target="_blank" rel="noopener noreferrer">WEB</a>
                        <a className="linkPadding" href={"https://www.coingecko.com/en/coins/" + row.original.coingecko_api_id} target="_blank" rel="noopener noreferrer">CG</a>
                        <a className="linkPadding" href={"https://twitter.com/" + row.original.twitter_handle} target="_blank" rel="noopener noreferrer">X</a>
                    </div>
                );
            },
        },
        //column definitions...
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        //enableStickyHeader: true,
        enableRowSelection: false,
        //getRowId: (row) => row.coin_id,
        initialState: { showColumnFilters: false },
        manualFiltering: false,
        enablePagination: true,
        manualPagination: false,
        manualSorting: false,
        enableColumnActions: false,
        enableStickyHeader: stickyHeaderChecked ? true : false,
        muiToolbarAlertBannerProps: isError? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(odd) > td': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#242424' : '#F4F4F4',            
                },
                '& tr:nth-of-type(even) > td': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#191919' : '#E9E9E9',            
                },
            },
        },
        renderTopToolbarCustomActions: () => 
            <Typography align="left">
                Snapshot as of {window.snapshotDate} (UTC)            
            </Typography>,
        renderToolbarInternalActions: ({ table }) => (
            <>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <Tooltip title="Sticky Header"><Switch stickyHeaderChecked={stickyHeaderChecked} onChange={handleStickyHeaderChange} /></Tooltip>
                <MRT_ToggleFullScreenButton table={table} />            
            </>        
        ),
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onDensityChange: setDensity,
        onGlobalFilterChange: setGlobalFilter,
        onShowColumnFiltersChange: setShowColumnFilters,
        onShowGlobalFilterChange: setShowGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,
        state: {
            columnFilters,
            columnVisibility,
            density,
            globalFilter,
            showColumnFilters,
            showGlobalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
    });

    return(<div>
        <h4 className="homeShill">
            MCT is brought to you by Nerva crypto community.
            Stop by our <a href="https://discord.gg/6PSS69WSTW" target="_blank">Discord</a> or <a href="https://t.me/NervaCrypto" target="_blank">Telegram</a> and say Hi!</h4>                   
        <MaterialReactTable table={table} />
        </div>
    );
};

export default Home;