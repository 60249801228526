import { Link } from "react-router-dom";

export default function Post({id, display_date, url_path, title, summary, image_url}) {
    return (
        <div className="blogIndexItem">
            <div className="blogIndexImage">
                <Link to={`/blog/${url_path}`}>
                    <img src={image_url} alt="" />
                </Link>
            </div>
            <div className="blogIndexText">
                <Link to={`/blog/${url_path}`}>
                    <h2>{title}</h2>
                </Link>
                <p className="blogIndexInfo">
                    Posted on <time>{display_date}</time> by anon
                </p>
                <p className="blogIndexSummary">{summary}</p>            
            </div>
        </div>
    );
}